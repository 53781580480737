import React, { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import { initializeApp } from 'firebase/app';

const QuestionContainer = React.lazy(() => import('./containers/question'));
const HomeContainer = React.lazy(() => import('./containers/home'));
const SubscribeContainer = React.lazy(() => import('./containers/subscribe'));
const UnsubscribeContainer = React.lazy(() => import('./containers/unsubscribe'));
const PrivacyContainer = React.lazy(() => import('./containers/privacy'));
const TermsContainer = React.lazy(() => import('./containers/terms'));
const ContactContainer = React.lazy(() => import('./containers/contact'));

const firebaseConfig = {
	apiKey: "AIzaSyBXFEkNhGKJgP_We4zacdi7PwFG38KD2pw",
	authDomain: "trivia-7527d.firebaseapp.com",
	projectId: "trivia-7527d",
	storageBucket: "trivia-7527d.appspot.com",
	messagingSenderId: "172629526227",
	appId: "1:172629526227:web:7ac74c94fad60874003626",
	measurementId: "G-3L3BWRRVYM"
};

// Initialize Firebase
initializeApp(firebaseConfig);

function App() {
    return (
          <Suspense fallback={<span></span>}>
                <Router>
                    <div className={'app-container'}>
                        <Switch>
                            <Route path='/unsubscribe'>
                                <UnsubscribeContainer />
                            </Route>
                            <Route path='/subscribe'>
                                <SubscribeContainer />
                            </Route>
                            <Route path='/question/:questionId'>
                                <QuestionContainer />
                            </Route>
                            <Route path='/privacy-policy'>
                                <PrivacyContainer />
                            </Route>
                            <Route path='/terms-of-use'>
                                <TermsContainer />
                            </Route>
                            <Route path='/contact-us'>
                                <ContactContainer />
                            </Route>
                            <Route path='/'>
                                <HomeContainer />
                            </Route>
                        </Switch>
                    </div>
                </Router>
          </Suspense>
    );
}

export default App;
